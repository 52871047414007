import { globalHistory } from "@reach/router";
import Disqus from "gatsby-plugin-disqus";
import * as React from "react";

import { HalfColumnWidthWrapper } from "../confines-wrappers";
import styled from "../../theme/styled";

interface IProps {
  id: string;
  title: string;
  path: string;
}

const DisqusStyled = styled.div`
  padding-top: 0 !important;
`;

const DisqusComp = ({
  id, title, path
}: IProps) => {
  const origin: string = globalHistory.location.origin;
  const canonicalUrl = `${origin}${path}`;

  return (
    <DisqusStyled className="disqus-container content-container">
      <HalfColumnWidthWrapper>
        <Disqus
          identifier={id}
          title={title}
          url={canonicalUrl}
        />
      </HalfColumnWidthWrapper>
    </DisqusStyled>
  );
};

export default DisqusComp;
